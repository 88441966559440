import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components"
import { Link } from "gatsby"
import Button from "../components/button"
import Arrow from "../images/svg/arrow.svg"
import Seo from "../components/seo"

const PostContainer = styled.div`
  .backlink {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--main-color);
    font-family: "Open Sans", sans-serif;
    transition: opacity 0.2s;

    svg {
      margin-top: 4px;
      margin-right: 8px;
      transform: translate(0px);
      transition: transform 0.2s;
    }

    &:hover {
      opacity: 1;
      transition: opacity 0.2s;

      svg {
        transform: translate(-4px);
        transition: transform 0.2s;
      }
    }
  }

  .content {
    margin: 56px 0;

    .date {
      font-style: italic;
      margin-bottom: 40px;
    }
  }
`

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark

  return (
    <Layout context="post">
      <Seo title={frontmatter.title} />
      <PostContainer>
        <Link className="backlink" to="/">
          <Arrow />
          volver al inicio
        </Link>
        <div className="content">
          <h1>{frontmatter.title}</h1>
          <p className="date">{frontmatter.date}</p>
          <p>{frontmatter.description}</p>
        </div>
        <Button label="Descargar PDF" downloadPath={`${frontmatter.file}`} />
      </PostContainer>
    </Layout>
  )
}
export const pageQuery = graphql`
  query ($title: String!) {
    markdownRemark(frontmatter: { title: { eq: $title } }) {
      frontmatter {
        title
        description
        date(formatString: "DD [de] MMMM YYYY", locale: "es-ES")
        file
      }
    }
  }
`
