import * as React from "react"
import styled from "styled-components"
import DownloadIcon from "../images/svg/download.svg"

const ButtonContainer = styled.a`
  cursor: pointer;
  background-color: var(--main-color);
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  padding: 24px 56px;
  border-radius: 4px;
  border: none;
  width: 100%;
  display: block;
  transition: background-color 0.2s;
  text-decoration: none;
  max-width: 340px;

  &:hover {
    background-color: var(--main-color-dark);
    transition: background-color 0.2s;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media only screen and (min-width: 1200px) {
    padding: 24px 80px;
  }
`

const Button = ({ label, downloadPath }) => {
  const n = downloadPath.lastIndexOf("/")
  const fileName = downloadPath.substring(n + 1)

  return (
    <>
      <ButtonContainer href={downloadPath} download={fileName}>
        <div>
          {label}
          <DownloadIcon />
        </div>
      </ButtonContainer>
    </>
  )
}

export default Button
